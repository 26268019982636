@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Bebas+Neue&family=Caveat+Brush&family=Crushed&family=Dancing+Script&family=Elsie&family=Lobster&family=Monoton&family=Passions+Conflict&family=Sacramento&family=Zilla+Slab+Highlight&family=Alegreya&family=Alegreya+Sans&family=Archivo+Narrow&family=BioRhyme&family=Chivo&family=Cormorant&family=DM+Sans&family=Eczar&family=Fira+Sans&family=IBM+Plex+Sans&family=Inconsolata&family=Inknut+Antiqua&family=Inter&family=Karla&family=Libre+Baskerville&family=Libre+Franklin&family=Lora&family=Manrope&family=Merriweather&family=Montserrat&family=Neuton&family=Open+Sans&family=PT+Sans&family=PT+Serif&family=Playfair+Display&family=Poppins&family=Proza+Libre&family=Raleway&family=Roboto+Slab&family=Rubik&family=Source+Sans+Pro&family=Source+Serif+Pro&family=Space+Grotesk&family=Space+Mono&family=Spectral&family=Syne&family=Work+Sans&display=swap');
div.grid {
    position: absolute;
    top: 15%;
    left: 15%;
    width: 70%;
    height: calc(100vh - 30%);
    border: 2px dashed #bababa;
    display: none;
}
div:hover~.grid {
    display: block;
}
/* #main{
    background-color: #fff
} */
.colorAccent {
    color: #FB8C00
}
.colorDark {
    color: #1B1C29
}
.colorLight {
    color: #DCDDE1
}
.bgAccent {
    background-color: #FB8C00
}
.bgDark {
    background-color: #1B1C29
}
.bgLight {
    background-color: #DCDDE1
}
.borderAccent {
    border-color: #FB8C00
}
.borderDark {
    border-color: #1B1C29
}
.borderLight {
    border-color: #DCDDE1
}
.hcenter {
    position: absolute;
    left: 50%!important;
    transform: translate(-50%, 0);
}
.hleft {
    position: absolute;
    left: 0px!important;
}
.hright {
    position: absolute;
    right: 0px!important;
}
.vcenter {
    position: absolute;
    top: 50%!important;
    transform: translate(-50%);
}
.vtop {
    position: absolute;
    top: 0px!important;
}
.vbottom {
    position: absolute;
    top: 100%!important;
    transform: translate(0, -100%);
}
.imageItem {
    background-image: url("../../../public/images/no-image.png");
    background-size: cover;
}
.textItem {
    width: 300px;
    height: 300px;
}