.resizable .resizers{
  width: 100%;
  height: 100%;
  border: 2px solid #4286f4;
  box-sizing: border-box;
}

.resizable .resizers .resizer{
  width: 30px;
  height: 30px;
  border-radius: 50%; /*magic to turn square into circle*/
  background: white;
  border: 1px solid #4286f4;
  position: absolute;
}
.resizable .resizers .delete-icon {
  display: block;
  position: absolute;
  padding: 3px 10px;
  border-radius: 20px;
  background: #eaeaea;
  right: 15px;
  top: -42px;
  cursor: pointer;
}
.delete-icon {
  display: none;
}
.resizable .resizers .resizer.top-left {
  left: -15px;
  top: -15px;
  cursor: nwse-resize; /*resizer cursor*/
}
.resizable .resizers .resizer.top-right {
  right: -15px;
  top: -15px;
  cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-left {
  left: -15px;
  bottom: -15px;
  cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-right {
  right: -15px;
  bottom: -15px;
  cursor: nwse-resize;
}
.layerSelect {
  background-color: #eaeaea;
  color: red;
}
.fc-daygrid-event {
  background-color: #1B1C29!important;
  border: 1px solid #1B1C29!important;
}